// Import Firebase utilities
import { auth, googleProvider } from './firebase/firebaseConfig';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

// Function for signing in with email and password
export const signInWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user; // Return the authenticated user
  } catch (error) {
    console.error('Error signing in with email and password:', error);
    throw error; // Re-throw error to allow the UI to handle it
  }
};

// Function for signing in with Google
export const signInWithGoogle = async () => {
  try {
    const userCredential = await signInWithPopup(auth, googleProvider);
    return userCredential.user; // Return the authenticated user
  } catch (error) {
    console.error('Error signing in with Google:', error);
    throw error; // Re-throw error to allow the UI to handle it
  }
};

// Function for logging out the current user
export const logout = async () => {
  try {
    await auth.signOut();
    console.log('User logged out successfully');
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

// Optional: Function for password reset
export const resetPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};
