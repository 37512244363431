import React, { useState } from 'react';
import SampleData from './SampleData';

const GridComponent = () => {
  const rowsData = SampleData();

  return (
    <div className="lottery-grid-wrapper">
      <div className="lottery-grid-display-container">
        <h1 className="lottery-grid-display-title">Lottery Grid Display</h1>
        {rowsData && rowsData.length > 0 ? rowsData.map((row, index) => (
          <RowComponent key={index} rowIndex={index} rowData={row} />
        )) : <p>No data available</p>}
      </div>
    </div>
  );
};

const RowComponent = ({ rowIndex, rowData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="row-summary" onClick={toggleExpand}>
        <span>November - Click to {isExpanded ? 'Collapse' : 'Expand'}</span>
      </div>
      {isExpanded && (
        <div>
          <div>
            <CorrectedResult rowData={rowData} />
          </div>
        </div>
      )}
    </div>
  );
};

const CorrectedResult = ({ rowData }) => {
  return (
    <table className="lottery-grid-display-table">
      <thead>
        <tr>
          {Object.keys(rowData).map((key, index) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(rowData)[0] && rowData[Object.keys(rowData)[0]].map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Object.keys(rowData).map((key, colIndex) => (
                <td key={colIndex} style={{ fontSize: '28px', padding: '10px', border: '1px solid #ccc' }}>{rowData[key][rowIndex]}</td>

            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GridComponent;

