import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Updated styling file
import './CreditsDisplay.css'; // Import CSS for CreditsDisplay styling
import CreditsDisplay from './CreditsDisplay'; // Import CreditsDisplay component
import { useAuth } from './firebase/authContext'; // Import Auth context
import { auth, db } from './firebase/firebaseConfig'; // Import Firestore configuration and auth
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import for navigation

const Navbar = () => {
  const [trialStatus, setTrialStatus] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { currentUser } = useAuth(); // Get the authenticated user from context
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);

      // Real-time listener for user data
      const unsubscribeUser = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setCredits(userData.credits || 0);
          setIsSubscribed(userData.isSubscribed || false);

          // Check trial status
          const currentDate = new Date();
          const trialEndDate = userData.trialEndDate?.toDate();
          if (trialEndDate && currentDate <= trialEndDate) {
            setTrialStatus('You are currently on a free 7-day trial.');
          } else {
            setTrialStatus('');
          }
        }
      });

      return () => unsubscribeUser();
    } else {
      // Reset state when user logs out
      setCredits(0);
      setIsSubscribed(false);
      setTrialStatus('');
    }
  }, [currentUser]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate('/logout');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <a href="/">
            <img src='/logo192.png' alt='Logo' className='logo-image'/>
          </a>
        </div>
        <div className="navbar-middle">
          <div className={`nav-elements ${isOpen ? 'active' : ''}`}>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/profile">Profile</a></li>
            
              {currentUser && (
                <>
                  <li className="credits-display">
                    <CreditsDisplay
                      credits={credits}
                      isSubscribed={isSubscribed}
                      isLoggedIn={!!currentUser}
                      userEmail={currentUser.email}
                    />
                  </li>
                  <li><button className="logout-button" onClick={handleLogout}>Logout</button></li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="nav-toggle" onClick={toggleNavbar}>
          <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </div>
      </div>
      {trialStatus && <p className="trial-status">{trialStatus}</p>}
    </nav>
  );
};

export default Navbar;
