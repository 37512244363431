// SampleData.js (create this as a separate file)
const SampleData = () => {
    return [
      {
        "A": ["446", "335", "224", "113", "002", "991", "880", "779", "668", "557", "446"],
        "B": ["449", "338", "227", "116", "005", "994", "883", "772", "661", "550", "449"],
        "C": ["464", "353", "242", "131", "020", "909", "898", "787", "676", "565", "464"],
        "D": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],
        "E": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],
        "F": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],
        "G": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],
        "H": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],
        "I": ["469", "358", "247", "136", "025", "914", "803", "692", "581", "470", "469"],


      }
    ];
  };
  
  export default SampleData;