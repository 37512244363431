// src/firebase/authContext.js
import React, { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig'; // Import the Firebase configuration
import { doc, getDoc, setDoc } from 'firebase/firestore';

// Create an Auth context
const AuthContext = React.createContext();

// Custom hook to use the Auth context
export function useAuth() {
  return useContext(AuthContext);
}

// AuthProvider Component
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Initial loading state while checking if the user is signed in

  // Monitor authentication changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Set the current user in the context
        setCurrentUser(user);

        // Initialize user data in Firestore if it does not exist
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          await setDoc(userRef, {
            email: user.email,
            credits: 0, // No initial credits during the free 7-day trial
            isSubscribed: false,
            trialEndDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7-day trial
            creditsExpiryDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7-day expiry for credits
          });
        }
      } else {
        setCurrentUser(null);
      }

      setLoading(false); // Set loading to false once we determine the auth state
    });

    // Cleanup subscription on component unmount
    return unsubscribe;
  }, []);

  const value = {
    currentUser, // Provide the currentUser object to the components that use this context
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only when not loading */}
    </AuthContext.Provider>
  );
}
