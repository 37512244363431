// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../components/firebase/authContext'; // Import useAuth from authContext
import LoadingSpinner from './LoadingSpinner'; // Import LoadingSpinner

// Component to handle protected routes
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth(); // Get current user from AuthProvider

  // Show loading spinner while checking the auth state
  if (currentUser === undefined) {
    return <LoadingSpinner />; // Show the custom loading spinner while loading
  }

  // If user is not authenticated, redirect to login page
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  // If user is authenticated, render the child component
  return children;
};

export default ProtectedRoute;
