import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, db, googleProvider } from './firebase/firebaseConfig'; 
import { setDoc, getDoc, doc } from 'firebase/firestore';
// import './Auth.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await setDoc(userRef, {
          email: user.email,
          credits: 0, // No initial credits during the free 7-day trial
          isSubscribed: false,
          trialEndDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) // 7-day trial
        });
      } else {
        const currentDate = new Date();
        const trialEndDate = userDoc.data().trialEndDate?.toDate();
        if (trialEndDate && currentDate > trialEndDate) {
          // Issue tier-level credits after trial ends
          await setDoc(userRef, {
            credits: 100, // Example tier-level credits after the trial ends, adjust accordingly
            isSubscribed: true
          }, { merge: true });
        }
        // Redirect to Stripe subscription page
        window.location.href = 'https://buy.stripe.com/eVa28GbxPapg1P24gj';
        return;
      }

      // Redirect to Stripe subscription page before navigating to the homepage
        window.location.href = 'https://buy.stripe.com/eVa28GbxPapg1P24gj';
        return;
    } catch (err) {
      setError('Sign up failed: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const { user } = await signInWithPopup(auth, googleProvider);
      console.log('User info:', user);
  
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        await setDoc(userRef, {
          email: user.email,
          credits: 0, // No initial credits during the free 7-day trial
          isSubscribed: false,
          trialEndDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) // 7-day trial
        });
      } else {
        const currentDate = new Date();
        const trialEndDate = userDoc.data().trialEndDate?.toDate();
        if (trialEndDate && currentDate > trialEndDate) {
          // Issue tier-level credits after trial ends
          await setDoc(userRef, {
            credits: 100, // Example tier-level credits after the trial ends, adjust accordingly
            isSubscribed: true
          }, { merge: true });
        }
        // Redirect to Stripe subscription page
        window.location.href = 'https://buy.stripe.com/eVa28GbxPapg1P24gj';
        return;
      }
  
      // Redirect to Stripe subscription page before navigating to the homepage
        window.location.href = 'https://buy.stripe.com/eVa28GbxPapg1P24gj';
        return;
    } catch (err) {
      console.error('Full error object:', err);
      if (err.code === 'auth/popup-closed-by-user') {
        setError('Google sign-in was canceled. Please try again.');
      } else {
        setError('Google sign-up failed: ' + err.message);
      }
    }
  };
  
  

  return (
    <div className="auth-container">
      <h2>Create an account</h2>
      <p>Please enter your details to sign up</p>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={handleInputChange(setEmail)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handleInputChange(setPassword)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleInputChange(setConfirmPassword)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Signing up...' : 'Sign up'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>

      <p className="footer-text">Or sign up using:</p>
      <button className="oauth-button google-signup" onClick={handleGoogleSignUp}>Sign up with Google</button>

      <p className="footer-text">
        Already have an account?{' '}
        <button className="link-button" onClick={() => navigate('/login')}>
          Sign in
        </button>
      </p>
    </div>
  );
}

export default SignUp;
