// Paywall.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { auth } from './firebase/firebaseConfig';
import './Paywall.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Paywall = () => {
  const [selectedTier, setSelectedTier] = useState('');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSelectTier = (tier) => {
    setSelectedTier(tier);
  };

  const handleBillingToggle = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  };

  const handleCheckout = async () => {
    const priceMap = {
      basic: {
        monthly: 'price_1QBXb4I59sBrVN1qM0ab5D3R',
        yearly: 'price_1QCBSXI59sBrVN1qLwFQWjnh',
      },
      pro: {
        monthly: 'price_1QBXb1I59sBrVN1qM3h1YZJm',
        yearly: 'price_1QCBTXI59sBrVN1qVe0R7PuG',
      },
      premium: {
        monthly: 'price_1QBXaxI59sBrVN1qPEc3d2sV',
        yearly: 'price_1QCBU8I59sBrVN1qv2nrzxPh',
      },
    };

    const priceId = priceMap[selectedTier]?.[billingCycle];
    if (!priceId) {
      alert('Please select a subscription tier.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;
      if (!user) {
        setError('User not authenticated. Please log in.');
        setLoading(false);
        return;
      }

      const userId = user.uid;

      const backendUrl =
        process.env.NODE_ENV === 'production'
          ? '/.netlify/functions/create-checkout-session'
          : 'http://localhost:8888/.netlify/functions/create-checkout-session';

      // Send priceId, userId to the backend
      const { data } = await axios.post(backendUrl, {
        priceId,
        userId,
      });

      const stripe = await stripePromise;

      // Redirect to Stripe Checkout using the sessionId
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      setError(`Error: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="paywall-container">
      <h1>Select Your Subscription</h1>
      {/* Billing cycle toggle */}
      <div className="billing-toggle">
        <label className={`toggle-label ${billingCycle === 'monthly' ? 'active' : ''}`}>Monthly</label>
        <label className="toggle-switch">
          <input type="checkbox" checked={billingCycle === 'yearly'} onChange={handleBillingToggle} />
          <span className="slider round"></span>
        </label>
        <label className={`toggle-label ${billingCycle === 'yearly' ? 'active' : ''}`}>Yearly (Save 20%)</label>
      </div>
      {/* Subscription tier buttons */}
      {/* Add the subscription plan code here */}
      <button className="checkout-btn" onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : 'Proceed to Checkout'}
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Paywall;
