import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailPassword, signInWithGoogle } from './authService'; // Import authService functions
import './Auth.css'; // Import styles

function Login() {
  const [formState, setFormState] = useState({ email: '', password: '', error: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value, error: '' }));
  };

  // Handle submit for email/password login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailPassword(formState.email, formState.password);
      navigate('/');
    } catch (err) {
      setFormState((prev) => ({
        ...prev,
        error: 'Login failed. Please check your credentials and try again.',
      }));
    } finally {
      setLoading(false);
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await signInWithGoogle();
      navigate('/');
    } catch (err) {
      setFormState((prev) => ({
        ...prev,
        error: 'Google sign-in failed. Please try again.',
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <h2>Login</h2>
      {formState.error && <div className="error-banner">{formState.error}</div>}

      <form onSubmit={handleSubmit}>
        <input
          name="email"
          type="email"
          placeholder="Email"
          value={formState.email}
          onChange={handleInputChange}
          required
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          value={formState.password}
          onChange={handleInputChange}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Signing in...' : 'Sign in'}
        </button>
      </form>

      <p>or</p>
      <button className="google-signin-btn" onClick={handleGoogleSignIn} disabled={loading}>
        {loading ? 'Signing in...' : 'Sign in with Google'}
      </button>

      <p>
        <span className="link-text" onClick={() => navigate('/reset-password')}>
          Forgot password?
        </span>
      </p>

      <p className="footer-text">
        Don't have an account?{' '}
        <button className="link-button" onClick={() => navigate('/signup')}>
          Sign up
        </button>
      </p>
    </div>
  );
}

export default Login;
