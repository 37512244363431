// CreditsDisplay Component for Navbar
const CreditsDisplay = ({ credits, isSubscribed, isLoggedIn, userEmail, creditsExpiringSoon }) => {
  if (!isLoggedIn) return null;

  return (
    <div className="credits-display-header">
      <h3>{userEmail}</h3>
      <h2>
        {credits === undefined
          ? "Loading credits..."
          : creditsExpiringSoon
          ? "Your credits are expiring soon. Renew now!"
          : isSubscribed
          ? `${credits} Credits Available`
          : `${credits} Credits`}
      </h2>
    </div>
  );
};

export default CreditsDisplay;
