import React, { useState, useRef, useEffect } from 'react';
import './ThreeSixNineThreeGenerator.css';
import HowTo from './HowTo';
import DateWidget from './DateWidget';

const number = '3693';
const initialRow = number.split('').map(digit => parseInt(digit, 10));
const emptyRow = Array(4).fill(0);

function ThreeSixNineThreeGenerator() {
  const [inputs, setInputs] = useState(Array(4).fill(''));
  const inputRefs = useRef([]);
  const [rows, setRows] = useState(Array(12).fill().map(() => Array(4).fill(0)));
  const [activeTab, setActiveTab] = useState('calculator');
  const [daySum, setDaySum] = useState(null);

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value) || value === '') {
      const newInputs = [...inputs];
      newInputs[index] = value;
      setInputs(newInputs);
      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const calculateNextRow = (initialRow, prevRow) => {
    return initialRow.map((val, index) => {
      const sum = (parseInt(initialRow[index], 10) || 0) + (parseInt(prevRow[index], 10) || 0);
      return sum % 10;
    });
  };

  const handleClear = () => {
    setInputs(Array(4).fill(''));
    const newRows = [initialRow, emptyRow, ...Array(10).fill(emptyRow)];
    setRows(newRows);
  };

  useEffect(() => {
    const inputRow = inputs.map(input => (input === '' ? 0 : parseInt(input, 10)));
    const newRows = [initialRow, inputRow];

    for (let i = 2; i < 12; i++) {
      newRows.push(calculateNextRow(initialRow, newRows[i - 1]));
    }

    setRows(newRows);
  }, [inputs]);

  return (
    <div className="threesixninethreegenerator">
      <div className="generator-container">
        
        
        
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'calculator' ? 'active' : ''}`}
            onClick={() => setActiveTab('calculator')}
            aria-label="Calculator Tab"
          >
            Rundown Generator
          </button>
          <button
            className={`tab-button ${activeTab === 'howto' ? 'active' : ''}`}
            onClick={() => setActiveTab('howto')}
            aria-label="How To Tab"
          >
            How To
          </button>
        </div>
        <div className="content-wrapper">
          <DateWidget onDaySumChange={setDaySum} />
          <div className="content-container">
            {activeTab === 'calculator' && (
              <div className="grid-wrapper">
                <div className="three-six-nine-three-label">Base Number</div>
                <div className="number-grid">
                  {rows[0].map((cell, cellIndex) => (
                    <div className="cell" key={cellIndex}>{cell}</div>
                  ))}
                </div>
                <div className="prev-draw-label" style={{fontSize: '25px'}}>Prev Draw</div>
                <div className="number-grid with-prev-draw">
                  {inputs.map((input, cellIndex) => (
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength="1"
                      className="input-cell"
                      value={input}
                      onChange={(event) => handleChange(cellIndex, event)}
                      ref={el => inputRefs.current[cellIndex] = el}
                      key={cellIndex}
                      aria-label={`Input cell ${cellIndex + 1}`}
                    />
                  ))}
                </div>
                {rows.slice(2).map((row, rowIndex) => (
                  <div className="number-grid" key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <div
                        className={`cell ${cell === daySum ? 'highlight' : ''}`}
                        key={cellIndex}
                      >
                        {cell}
                      </div>
                    ))}
                  </div>
                ))}
                <button className="clear-button" onClick={handleClear} aria-label="Clear Inputs">
                  Clear
                </button>
              </div>
            )}
            {activeTab === 'howto' && (
              <div className="how-to-container">
                <h2>How To Use</h2>
                <ol>
                  <li>Enter your numbers from your previous draw in the input cells in the second row.</li>
                  <li>The subsequent rows will be calculated automatically based on the base number and your input.</li>
                  <li>The day sum is calculated based on the current date, it will be highlighted in the grid.</li>
                  <li>The day sum is derived from adding the month and the day, keeping only the second digit of the result.</li>
                  <li>Press the "Clear" button to reset the inputs and calculations.</li>
                </ol>
                <h3>Companion Books</h3>
                <div className="book-card-wrapper">
                  <div className="book-card">
                    <a href='/pick-4-3693-method'>
                      <img src="https://m.media-amazon.com/images/I/61DyQv0m7EL._SL1499_.jpg" alt="Pick 4 Lottery Strategy: 3-6-9-3 Workbook for Effective Number Tracking: Win The Lottery in Any State Pick 4 Lottery Book" />
                    </a>
                  </div>
                  <div className="book-card">
                    <a href='/cash-4-lottery-strategy'>
                      <img src="https://m.media-amazon.com/images/I/61Ms8Ct2UpL._SL1499_.jpg" alt="Cash 4 Lottery Strategy Workbook: Number Tracking for Winning" />
                    </a>
                  </div>
                  <div className="book-card">
                    <a href='/pick-4-3693-method-texas'>
                      <img src="https://m.media-amazon.com/images/I/61xQhw98p0L._SL1499_.jpg" alt="Texas 4 Workbook" />
                    </a>
                  </div>
                  <div className="book-card">
                    <a href='/pick-4-3693-method-large-format'>
                      <img src="https://m.media-amazon.com/images/I/61mlMTdIo-L._SL1293_.jpg" alt="Pick 4 Lottery Strategy: 3-6-9-3 Workbook for Effective Number Tracking: Win The Lottery in Any State Pick 4 Lottery Book" />
                    </a>
                  </div>
                  <div className="book-card">
                    <a href='/cash-4-large-format'>
                      <img src="https://m.media-amazon.com/images/I/61KCY+7oU2L._SL1293_.jpg" alt="Cash 4 Lottery Strategy Workbook: Number Tracking for Winning" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeSixNineThreeGenerator;
